<template>
  <div>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refCompanyProfileSettingsFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="isPhoneNumberValid ? handleSubmit(onSubmit) : true"
      >
        <b-row>
          <b-col
            cols="12"
            xl="10"
            class="mb-3"
          >
            <b-card no-body>
              <b-card-body>
                <rc-overlay
                  :show="loading"
                  no-fade
                  variant="white"
                >
                  <b-card-title>
                    {{ $t('settings.profile.title') }}
                  </b-card-title>
                  <b-card-sub-title>
                    {{ $t('settings.profile.description') }}
                  </b-card-sub-title>

                  <b-row class="mb-1 mt-2">
                    <b-col
                      cols="12"
                      class="mb-50"
                    >
                      <h5>
                        {{ $t('settings.profile.contact-data') }}
                      </h5>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mb-50"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.profile.short-name')"
                        rules="required|min:3|max:32"
                      >
                        <rc-label
                          for="short-name"
                          :description="$t('settings.profile.short-name-description')"
                        >
                          {{ $t('settings.profile.short-name') }}
                        </rc-label>
                        <b-form-input
                          id="short-name"
                          v-model="organizationData.shortName"
                          type="text"
                          minlength="3"
                          maxlength="32"
                          :placeholder="$t('settings.profile.short-name-placeholder')"
                          autocomplete="chrome-off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mb-50"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.profile.contact-email')"
                        rules="required|email"
                      >
                        <rc-label
                          for="contact-email"
                          :description="$t('settings.profile.contact-email-description')"
                        >
                          {{ $t('settings.profile.contact-email') }}
                        </rc-label>
                        <b-form-input
                          id="contact-email"
                          v-model="organizationData.email"
                          type="email"
                          :placeholder="$t('settings.profile.contact-email-placeholder')"
                          autocomplete="chrome-off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mb-50"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.profile.contact-phone')"
                        rules="required"
                      >
                        <rc-label
                          for="contact-phone"
                          :description="$t('settings.profile.contact-phone-description')"
                        >
                          {{ $t('settings.profile.contact-phone') }}
                        </rc-label>
                        <vue-tel-input
                          id="contact-phone"
                          v-model="organizationData.phone"
                          mode="international"
                          :dropdown-options="{ showFlags: true, showDialCodeInSelection: false, showSearchBox: true }"
                          :input-options="{ placeholder: '', showDialCode: false }"
                          :valid-characters-only="true"
                          @validate="handlePhoneNumberValidation"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="errors.length === 0 && !isPhoneNumberValid"
                          class="text-danger"
                        >{{ $t('validations.messages.phone-number-invalid') }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mb-50 mt-2"
                    >
                      <h5>
                        {{ $t('settings.profile.registration-data') }}
                        <rc-help :description="$t('settings.profile.registration-data-description')" />
                      </h5>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mb-50"
                    >
                      <address-form v-model="organizationData.address" />
                    </b-col>

                  </b-row>
                </rc-overlay>
              </b-card-body>

              <b-card-footer>

                <!-- Form Actions -->
                <div class="d-flex">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="processing || loading"
                  >
                    {{ $t('shared.btn.update') }}
                  </b-button>
                </div>
              </b-card-footer>
            </b-card>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import companyDetailsSettingsTabSetup from '@/views/organization/settings/profile/tabs/companyDetailsSettingsTabSetup'
import RcLabel from '@/layouts/components/rc/RcLabel'
import RcHelp from '@/layouts/components/rc/RcHelp'
import AddressForm from '@/views/_global/AddressForm'

export default {
  components: {
    AddressForm,
    RcHelp,
    RcLabel,
    RcOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup: companyDetailsSettingsTabSetup,
}
</script>
