<template>
  <div>
    <b-tabs
      v-if="false"
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- profile information settings tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <span class="font-weight-bold">
            {{ $t('settings.profile.title') }}
          </span>
        </template>

        <company-details-settings-tab />
      </b-tab>
      <!--/ profile information settings tab -->
    </b-tabs>

    <company-details-settings-tab />
  </div>
</template>

<script>
import profileSettingsSetup from '@/views/organization/settings/profile/profileSettingsSetup'
import CompanyDetailsSettingsTab from '@/views/organization/settings/profile/tabs/CompanyDetailsSettingsTab'

export default {
  components: { CompanyDetailsSettingsTab },
  setup: profileSettingsSetup,
}
</script>

<style scoped>

</style>
