var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refCompanyProfileSettingsFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();_vm.isPhoneNumberValid ? handleSubmit(_vm.onSubmit) : true}}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12","xl":"10"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('rc-overlay',{attrs:{"show":_vm.loading,"no-fade":"","variant":"white"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('settings.profile.title'))+" ")]),_c('b-card-sub-title',[_vm._v(" "+_vm._s(_vm.$t('settings.profile.description'))+" ")]),_c('b-row',{staticClass:"mb-1 mt-2"},[_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('settings.profile.contact-data'))+" ")])]),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('settings.profile.short-name'),"rules":"required|min:3|max:32"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('rc-label',{attrs:{"for":"short-name","description":_vm.$t('settings.profile.short-name-description')}},[_vm._v(" "+_vm._s(_vm.$t('settings.profile.short-name'))+" ")]),_c('b-form-input',{attrs:{"id":"short-name","type":"text","minlength":"3","maxlength":"32","placeholder":_vm.$t('settings.profile.short-name-placeholder'),"autocomplete":"chrome-off"},model:{value:(_vm.organizationData.shortName),callback:function ($$v) {_vm.$set(_vm.organizationData, "shortName", $$v)},expression:"organizationData.shortName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('settings.profile.contact-email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('rc-label',{attrs:{"for":"contact-email","description":_vm.$t('settings.profile.contact-email-description')}},[_vm._v(" "+_vm._s(_vm.$t('settings.profile.contact-email'))+" ")]),_c('b-form-input',{attrs:{"id":"contact-email","type":"email","placeholder":_vm.$t('settings.profile.contact-email-placeholder'),"autocomplete":"chrome-off"},model:{value:(_vm.organizationData.email),callback:function ($$v) {_vm.$set(_vm.organizationData, "email", $$v)},expression:"organizationData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('settings.profile.contact-phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('rc-label',{attrs:{"for":"contact-phone","description":_vm.$t('settings.profile.contact-phone-description')}},[_vm._v(" "+_vm._s(_vm.$t('settings.profile.contact-phone'))+" ")]),_c('vue-tel-input',{attrs:{"id":"contact-phone","mode":"international","dropdown-options":{ showFlags: true, showDialCodeInSelection: false, showSearchBox: true },"input-options":{ placeholder: '', showDialCode: false },"valid-characters-only":true},on:{"validate":_vm.handlePhoneNumberValidation},model:{value:(_vm.organizationData.phone),callback:function ($$v) {_vm.$set(_vm.organizationData, "phone", $$v)},expression:"organizationData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(errors.length === 0 && !_vm.isPhoneNumberValid)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('validations.messages.phone-number-invalid')))]):_vm._e()]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50 mt-2",attrs:{"cols":"12"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('settings.profile.registration-data'))+" "),_c('rc-help',{attrs:{"description":_vm.$t('settings.profile.registration-data-description')}})],1)]),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('address-form',{model:{value:(_vm.organizationData.address),callback:function ($$v) {_vm.$set(_vm.organizationData, "address", $$v)},expression:"organizationData.address"}})],1)],1)],1)],1),_c('b-card-footer',[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","type":"submit","disabled":_vm.processing || _vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('shared.btn.update'))+" ")])],1)])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }