import { computed, ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import store from '@/store'
import login from '@/http/requests/auth/login'
import organization from '@/http/requests/organization'
import { parseRequestError } from '@/helpers/helpers'
import i18n from '@/libs/i18n'

export default () => {
  const me = computed(() => store.getters['auth/getUser'])
  const processing = ref(false)
  const isPhoneNumberValid = ref(true)
  const isRegisteredCompany = ref(true)
  const loading = ref(true)
  const refCompanyProfileSettingsFormObserver = ref()
  const organizationId = ref(null)
  const organizationData = ref({
    address: {
      companyName: null,
      addressLine1: null,
      zipCode: null,
      city: null,
      taxIdentifier: null,
    },
    billingAddress: {
      companyName: null,
      addressLine1: null,
      zipCode: null,
      city: null,
      taxIdentifier: null,
    },
  })

  login.userOrganizationInfo()
    .then(res => {
      organizationId.value = res.data.id
      organizationData.value = res.data
      isRegisteredCompany.value = !!res.data.address.taxIdentifier
    })
    .catch(err => parseRequestError(err))
    .finally(() => {
      loading.value = false
    })

  const onSubmit = () => {
    processing.value = true
    organization.patchOrganization(
      organizationId.value,
      organizationData.value,
    )
      .then(() => {
        window.toast.notify.success(i18n.t('settings.profile.toast.updated'))
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        processing.value = false
      })
  }

  const handlePhoneNumberValidation = phoneObject => {
    refCompanyProfileSettingsFormObserver.value.reset()
    isPhoneNumberValid.value = phoneObject.valid === undefined || phoneObject.valid
  }

  return {
    me,
    processing,
    isPhoneNumberValid,
    isRegisteredCompany,
    loading,
    refCompanyProfileSettingsFormObserver,
    onSubmit,
    organizationData,
    handlePhoneNumberValidation,

    required,
    alphaNum,
  }
}
